import { connect } from 'react-redux';
import React, { createContext, useContext, useReducer } from "react";
import { Route, Redirect } from 'react-router-dom';
import { BehaviorSubject, Subject } from 'rxjs';
import { history, handleResponse, encryptDecrypt, authHeader, getUserId } from '../authorize/_helpers';
import { CoreBaseAPI } from '../GlobalConfig';
import axios from 'axios';
import * as globalConfig  from './../GlobalConfig';


let currentStatusType = new BehaviorSubject([{ statusData: [], isLoaded: false }])
let parameterSetupData = new BehaviorSubject([])
let showEMRPatientPage = new BehaviorSubject([{ show_status: false, encounterStatusCode: '', patient_id: 0, encounter_id: 0 }]);
let emrPatientDetails = new BehaviorSubject([]);
let subject = new BehaviorSubject(false);
let emgPatientDetails = new BehaviorSubject([]);
let currentModulePage = new BehaviorSubject(0);
let pagePropInformation = new BehaviorSubject({})
let roleNotification = new BehaviorSubject('');
let pinValidationPageMaster = new BehaviorSubject([])

const dataService = {
    setStatusTypeData,
    getStatusTypeData,

    setParameterSetup,
    getParameterSetupData,

    setShowEMRPatientPage,
    getShowEMRPatientPage,

    setEMRPatientDetails,
    getEMRPatientDetails,

    setShowEMGPatientPage,
    getShowEMGPatientPage,

    setCurrentModulePage,
    getCurrentModulePage,

    setPagePropInformation,
    getPagePropInformation,

    setpinValidationPageMaster,
    getpinValidationPageMaster

}
export const MasterDataService = {
    currentStatusType,
    parameterSetupData,
    dataService,

    sendMessage: message => subject.next({ text: message }),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable(),

    setRoleNotification: message => roleNotification.next(message),
    getRoleNotification: () => roleNotification.asObservable(),
    clearRoleNotification: () => roleNotification.next(''),
    getReportStyle
}


async function getReportStyle(data) {
    let url = CoreBaseAPI + '/RPT_ReportPrintParameter/List';
    let resposedata = {}
    await axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authHeader(),
            'enteredBy': getUserId(),
            'requestBy': 'user',
            'timeZoneDifference': globalConfig.timezoneOffsetMinutes

        }
    }).then(
        (response) => {
            resposedata = response.data
            console.log('response get style')
        }
    )
        .catch((error) => resposedata = error)
    return resposedata;

}


function setStatusTypeData(data) {
    currentStatusType.next(data);
}
function getStatusTypeData() {
    return currentStatusType.asObservable()
}

function setParameterSetup(data) {
    parameterSetupData.next(data);
}

function getParameterSetupData() {
    return parameterSetupData.asObservable();
}
function setShowEMRPatientPage(value) {
    showEMRPatientPage.next(value);
}
function getShowEMRPatientPage() {
    return showEMRPatientPage.asObservable();
}
function setEMRPatientDetails(data) {
    emrPatientDetails.next(data);
}
function getEMRPatientDetails() {
    return emrPatientDetails.asObservable();
}
function setShowEMGPatientPage(data) {
    emgPatientDetails.next(data);
}
function getShowEMGPatientPage() {
    return emgPatientDetails.asObservable();
}

function setCurrentModulePage(page) {
    currentModulePage.next(page);
}
function getCurrentModulePage() {
    return currentModulePage.asObservable();
}
function setPagePropInformation(data) {
    pagePropInformation.next(data);
}
function getPagePropInformation(data) {
    return pagePropInformation.asObservable();
}
function setpinValidationPageMaster(data) {
    return pinValidationPageMaster.next(data)
}
function getpinValidationPageMaster() {
    return pinValidationPageMaster.asObservable();
}
